import React, { useContext, useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useParams } from "react-router-dom";
import { httpReauest } from "../utils/httpRequest";
import Loading from "../components/common/Loading";
import SliderSimilier from "../components/SliderSimilier";
import { CDN_BASE_URL } from "../config";
import { FaBox, FaGift } from "react-icons/fa6";
import CheckoutForm from "../components/CheckoutForm";
import { CartContext } from "../context/CartContext";
import { calculateGoldPrice } from "../utils/getPrice";
import { priceComma } from "../utils/priceComma";
import { Helmet } from "react-helmet";
import CheckoutPrice from "../components/CheckoutPrice";

const CheckoutPage = () => {
  const [loading, setloading] = useState();
  const [productData, setproductData] = useState();
  const [packing, setpacking] = useState("free");

  const { cart, setCart, goldPrice, allPrice, setallPrice } =
    useContext(CartContext);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="hero_area">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"دکتر گلد | تکمیل سفارش"}</title>
        <meta
          name="description"
          content={
            "جستجو و خرید انواع زیورآلات طلا با طراحی‌های خاص و قیمت مناسب در دکتر گلد"
          }
        />
        <meta
          name="og:description"
          content={
            "جستجو و خرید انواع زیورآلات طلا با طراحی‌های خاص و قیمت مناسب در دکتر گلد"
          }
        />
        <meta name="og:title" content={"دکتر گلد | تکمیل سفارش"} />
      </Helmet>
      <Header />
      <div className=" overflow-hidden  py-10  bg-white text-right">
        <div class="grid sm:px-10 lg:grid-cols-2 lg:px-20 xl:px-32">
          <div class="px-4 ">
            <CheckoutPrice packing={packing} checkoutPage />

            <p class="mt-8 text-lg font-medium">دسته بندی</p>
            <form dir="rtl" class="mt-6 grid gap-6">
              <div class="relative">
                <input
                  class="peer hidden"
                  id="radio_1"
                  type="radio"
                  name="radio"
                  onClick={() => {
                    if (packing !== "pree") {
                      setpacking("pree");
                      setallPrice(allPrice + 200000);
                    }
                  }}
                  checked={packing === "pree"}
                />
                <span class="peer-checked:border-gray-700 absolute left-4 top-1/2 box-content block h-3 w-3 -translate-y-1/2 rounded-full border-8 border-gray-300 bg-white"></span>
                <label
                  class="peer-checked:border-2 peer-checked:border-gray-700 peer-checked:bg-gray-50 flex cursor-pointer select-none rounded-lg border border-gray-300 p-4"
                  for="radio_1"
                >
                  <FaGift size={35} className=" object-contain" />
                  <div class="mr-6">
                    <span class="mt-2 font-semibold">
                      دسته بندی مخصوص دکتر گلد
                    </span>
                    <p class="text-slate-500 text-sm leading-6">
                      هزینه 200/000 تومان
                    </p>
                  </div>
                </label>
              </div>
              <div class="relative">
                <input
                  class="peer hidden"
                  id="radio_2"
                  type="radio"
                  name="radio"
                  onClick={() => {
                    if (packing !== "free") {
                      setpacking("free");
                      setallPrice(allPrice - 200000);
                    }
                  }}
                  checked={packing === "free"}
                />
                <span class="peer-checked:border-gray-700 absolute left-4 top-1/2 box-content block h-3 w-3 -translate-y-1/2 rounded-full border-8 border-gray-300 bg-white"></span>
                <label
                  class="peer-checked:border-2 peer-checked:border-gray-700 peer-checked:bg-gray-50 flex cursor-pointer select-none rounded-lg border border-gray-300 p-4"
                  for="radio_2"
                >
                  <FaBox size={35} className=" object-contain" />
                  <div class="mr-6">
                    <span class="mt-2 font-semibold">دسته بندی ساده</span>
                    <p class="text-slate-500 text-sm leading-6">هزینه رایگان</p>
                  </div>
                </label>
              </div>
            </form>
          </div>

          <CheckoutForm packing={packing} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CheckoutPage;
