import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { useState } from "react";
import {
  FaArrowDown,
  FaArrowLeft,
  FaCartPlus,
  FaCircle,
  FaFileCode,
  FaSquare,
  FaThermometerEmpty,
  FaUser,
  FaWallet,
} from "react-icons/fa";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

const questions = [
  {
    icon: <FaUser />,
    name: "حساب کاربری",
    questions: [
      {
        question:
          "ایا برای خرید از گالری طلای دکتر گلد حتما باید در سایت عضو باشیم؟",
        answer:
          "خیر , برای ثبت سفارش از سایت دکتر گلد لازم نیست حتما عضو باشید با پر کردن فرم اطلاعات با پیامک وضعیت سفارش شما با شما در میان گذاشته میشود",
      },
      {
        question:
          "ایا میتوانیم به اطلاعات خریدهای قبلی خود دسترسی داشته باشیم؟",
        answer:
          "برای دسترسی به اطلاعات خریدهای قبلی باید با حساب کاربری خود وارد شوید سپس روی گزینه سفارش ها کلیک کنید تا سوابق خرید شما نشان داده شود.",
      },
    ],
  },
  {
    icon: <FaCartPlus />,
    name: "ثبت و پیگیری سفارش",
    questions: [
      {
        question: "از وضعیت سفارشی که ثبت کرده ام چگونه می توانم مطلع شوم؟",
        answer:
          "برای اطلاع از وضعیت سفارش میتوانید وارد حساب کاربری خود شوید و با کلیک روی گزینه سفارش ها از وضعیت ان مطلع شوید. همچنین وضعیت سفارش برای شما پیامک میشود",
      },
      {
        question:
          "بعد از ثبت سفارش برای اطمینان از پردازش ان باید با گالری طلای دکتر گلد تماس بگیریم؟",
        answer:
          "خیر پس از انجام مراحل خرید وثبت سفارش پردازش سفارش شما به صورت خودکار از طریق سرویس پیامک یا ایمیل اطلاع رسانی خواهد شد ودر صورتی که نیاز باشد کارشناسان فروش گالری طلای دکتر گلد با شما تماس خواهند گرفت. و همچنین با ورود به بخش حساب کاربری خود میتوانید از سفارشات خود مطلع شوید.",
      },
      {
        question: "ایا میتوانم سفارشم را ابطال کرده ویا تغییر بدهم؟",
        answer:
          "تا پیش از تایید نهایی سفارش و کلیک روی ایکون اتمام خرید شما میتوانید سفارش را از سبد خرید خود حذف ویا اصلاح کنید.پس از تایید نهایی و اتمام خرید ودر مرحله پرداخت وثبت سبد شما قادر به حذف وتغییر سفارش نیستند.از این مرحله به بعد برای لغو وتغییران با واحد پیگیری سفارشات تلفنی ویا بخش پشتیبانی تماس بگیرید.",
      },
      {
        question: "ایا قیمت محصولات در سایت گالری طلای دکتر گلد به روز هستند؟",
        answer:
          "بله گالری طلای دکتر گلد به طور منظم و روزانه قیمت محصولات عرضه شده در وب سایت خود را کنترل و به روز رسانی میکند.",
      },
    ],
  },
  {
    icon: <FaWallet />,
    name: "ارسال سفارش",
    questions: [
      {
        question:
          "پس از اینکه سفارش خود را ثبت کرده ام چقدر طول میکشد که به دستم برسد؟",
        answer:
          "زمان ارسال سفارش برای مشتریان عزیز در اصفهان در صورت ثبت سفارش تا قبل از ساعت 13 در همان روز یا در روز های بعد وبرای مشتریان عزیز در شهرستان هم 3 روز کاری میباشد.",
      },
      {
        question:
          "ایا میتوانیم برای شخص دیگری از گالری طلای دکتر گلد خرید کنیم وسفارش تحویل ان شخص شود؟",
        answer:
          "بله بعد از انتخاب محصول وقرار ان در سبد خرید در مرحله ارسال اطلاعات شما میتوانید ادرس ومشخصات گیرنده را وارد کنید.",
      },
      {
        question: "چگونه می توانم سفارشم را پیگیری کنم؟",
        answer:
          "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیازلورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چ",
      },
    ],
  },
  {
    icon: <FaFileCode />,
    name: "گارانتی و خدمات پس از فروش",
    questions: [
      {
        question:
          "اگر محصولی که خریداری کرده ام ایراد فنی یا اسیب دیدگی داشت چه اقدامی باید انجام دهم؟",
        answer: "با تیم پشتیبانی تماس گرفته شود.",
      },
    ],
  },
];

const FAQPage = () => {
  const [selected, setSelected] = useState("حساب کاربری");

  return (
    <div className="hero_area">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"دکتر گلد | سوالات متداول"}</title>
        <meta
          name="description"
          content={
            "جستجو و خرید انواع زیورآلات طلا با طراحی‌های خاص و قیمت مناسب در دکتر گلد"
          }
        />
        <meta
          name="og:description"
          content={
            "جستجو و خرید انواع زیورآلات طلا با طراحی‌های خاص و قیمت مناسب در دکتر گلد"
          }
        />
        <meta name="og:title" content={"دکتر گلد | سوالات متداول"} />
      </Helmet>
      <Header />
      <div dir="rtl" className=" bg-white py-8 text-right lg:px-16 ">
        <div className="relative  z-10 pb-8 lg:pt-10 flex-col items-center justify-center flex">
          <h1 className="text-center font-bold justify-center flex gap-2 items-center  text-[24px] lg:text-[36px] ">
            <FaSquare size={12} color="#F37324" />
            سوالات متداول
          </h1>
        </div>
        <div className="bg-white px-3 xl:px-16 lg:py-10 2xl:px-20 grid lg:grid-cols-3 gap-5">
          <span className="bg-[#F5F8FC] rounded-[20px] px-2 py-8 hidden lg:block">
            <img
              src="/logo.PNG"
              className="w-[130px] mx-auto"
              alt="Dr Gold Logo"
            />
            <div className="pt-8">
              <h3 className="text-[#205398] text-[20px] p-4">
                دسته بندی پرسش ها
              </h3>
              <div className=" text-[#336CA4] lg:text-[16px] px-2">
                {questions.map((item) => (
                  <button
                    onClick={() => setSelected(item.name)}
                    className="py-4 w-full items-center flex border-b border-[#E4EAF0] justify-between"
                  >
                    <span className="flex items-center gap-2">{item.name}</span>
                    <FaArrowLeft className="me-4" />
                  </button>
                ))}
              </div>
            </div>
          </span>
          <span className="lg:col-span-2">
            <div className="lg:hidden grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-5 px-7 mb-10">
              <span className="col-span-full text-[#000] text-[20px] text-center">
                دسته بندی پرسش ها
              </span>
              {questions?.map((item) => {
                if (item.name === selected) {
                  return (
                    <button
                      onClick={() => setSelected(item.name)}
                      className="border border-[#E4EAF0] bg-[#000] rounded-[15px] flex flex-col items-center justify-center p-6 text-[#fff] text-[14px] gap-3"
                    >
                      {item.name}
                    </button>
                  );
                }
                return (
                  <button
                    onClick={() => setSelected(item.name)}
                    className="border border-[#E4EAF0] bg-[#F5F8FC] rounded-[15px] flex flex-col items-center justify-center p-6 text-[#336CA4] text-[14px] gap-3"
                  >
                    {item.name}
                  </button>
                );
              })}
            </div>
            <div className="lg:hidden">
              {questions
                .find((e) => selected == e.name)
                .questions.map((q, index) => (
                  <Accordion
                    sx={{
                      boxShadow: "none",
                      border: "none",
                      outline: "none",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<FaArrowDown />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{
                        boxShadow: "none",
                        border: "none",
                        outline: "none",
                      }}
                      className="text-[12px] font-[600]"
                    >
                      <span className="text-[#F37324] font-[Dana-farsi-bold] me-2">
                        {index + 1}
                      </span>{" "}
                      {q.question}
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        boxShadow: "none",
                        border: "none",
                        outline: "none",
                      }}
                      className="text-[10px] text-[#7E8AAB]"
                    >
                      {q.answer}
                    </AccordionDetails>
                  </Accordion>
                ))}
            </div>

            <div className="border-[#E4EAF0] border rounded-[16px] hidden lg:block p-5">
              <h3 className=" lg:text-[24px] mb-5 font-bold">
                {questions.find((e) => selected == e.name).name}
              </h3>
              {questions
                .find((e) => selected == e.name)
                .questions.map((q) => (
                  <div className="mt-8">
                    <h5 className=" flex items-center gap-2 font-bold">
                      <FaCircle size={12} color="#F37324" />
                      {q.question}
                    </h5>
                    <p className="text-[#626E94] mt-3">{q.answer}</p>
                  </div>
                ))}
            </div>
          </span>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FAQPage;
