import React from "react";
import Header from "../components/Header";
import SliderSection from "../components/Slider";
import AboutSection from "../components/AboutSection";
import WhySection from "../components/WhySection";
import TestimonialSection from "../components/Testmonials";
import ContactUs from "../components/ContactUs";
import InfoSection from "../components/InfoSection";
import TeamComponent from "../components/TeamSection";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

const Howbuypage = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"دکتر گلد | راهنمای خرید"}</title>
        <meta
          name="description"
          content={
            "جستجو و خرید انواع زیورآلات طلا با طراحی‌های خاص و قیمت مناسب در دکتر گلد"
          }
        />
        <meta
          name="og:description"
          content={
            "جستجو و خرید انواع زیورآلات طلا با طراحی‌های خاص و قیمت مناسب در دکتر گلد"
          }
        />
        <meta name="og:title" content={"دکتر گلد | راهنمای خرید"} />
      </Helmet>
      <Header />
      <div className="bg container mx-auto p-3">
        <div className="py-1 text-center">
          <h1 className="title font-bold text-[24px] lg:text-[32px]">
            راهنمای خرید آنلاین از وبسایت دکتر گلد
          </h1>
        </div>
        <div className="grid grid-cols-3 my-12 gap-6 ">
          <span className="col-span-full">
            <div class="blog-content" style={{ textAlign: "right" }}>
              <h2
                dir="RTL"
                className="text-[20px] font-bold mt-[3rem] mb-[1.5rem] lg:text-[24px] "
              >
                {" "}
                1- محصول مورد نظر خود را جستجو کنید.
              </h2>
              <img src="/guide/1 (4).png" className="w-full" />
              <h2
                dir="RTL"
                className="text-[20px] font-bold mt-[3rem] mb-[1.5rem] lg:text-[24px] "
              >
                {" "}
                2- پس از یافتن محصول، آنرا انتخاب کنید.
              </h2>
              <img src="/guide/1 (5).png" className="w-full" />

              <h2
                dir="RTL"
                className="text-[20px] font-bold mt-[3rem] mb-[1.5rem] lg:text-[24px] "
              >
                {" "}
                3- پس از اطمینان از مشخصات و قیمت، افزودن به سبد خرید را انتخاب
                کنید.
              </h2>
              <img src="/guide/1 (3).png" className="w-full" />
              <h2
                dir="RTL"
                className="text-[20px] font-bold mt-[3rem] mb-[1.5rem] lg:text-[24px] "
              >
                {" "}
                4- سبد خرید خود را کنترل نمایید و سپس تکمیل خرید را انتخاب کنید.
              </h2>
              <img src="/guide/1 (2).png" className="w-full" />
              <h2
                dir="RTL"
                className="text-[20px] font-bold mt-[3rem] mb-[1.5rem] lg:text-[24px] "
              >
                {" "}
                5-اطلاعات خود را وارد کنید و فیش پرداختی را آپلود کنید
              </h2>
              <img src="/guide/1 (1).png" className="w-full" />
              <h2
                dir="RTL"
                className="text-[20px] font-bold mt-[3rem] mb-[1.5rem] lg:text-[24px] text-center "
              >
                {" "}
                سپاس از خرید شما
              </h2>
            </div>
          </span>
        </div>
      </div>
      {/* <InfoSection /> */}
      <Footer />
    </div>
  );
};

export default Howbuypage;
