import React from "react";
import Header from "../components/Header";
import SliderSection from "../components/Slider";
import AboutSection from "../components/AboutSection";
import WhySection from "../components/WhySection";
import TestimonialSection from "../components/Testmonials";
import ContactUs from "../components/ContactUs";
import InfoSection from "../components/InfoSection";
import TeamComponent from "../components/TeamSection";
import Filter from "../components/Filter";
import { useFormik } from "formik";
import Products from "../components/Products";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import Banners from "../components/Banners";

const Productspage = () => {
  const formik = useFormik({
    initialValues: {
      min_price: "",
      max_price: "",
      category: "",
      haveImg: "",
      onFire: "",
      search_key: "",
      search_type: "product",
    },
  });
  return (
    <div className="hero_area">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {"فروش آنلاین زیورآلات و جواهرات طلا با طراحی خاص | دکتر گلد"}
        </title>
        <meta
          name="description"
          content={
            "خرید انواع زیورآلات و جواهرات طلا با کیفیت عالی و قیمت مناسب در دکتر گلد خرید آنلاین انواع زیورآلات و جواهرات طلا"
          }
        />
        <meta
          name="og:description"
          content={
            "خرید انواع زیورآلات و جواهرات طلا با کیفیت عالی و قیمت مناسب در دکتر گلد خرید آنلاین انواع زیورآلات و جواهرات طلا"
          }
        />
        <meta
          name="og:title"
          content={
            "فروش آنلاین زیورآلات و جواهرات طلا با طراحی خاص | دکتر گلد"
          }
        />
      </Helmet>
      <Header />

      <div
        dir="rtl"
        className="grid lg:grid-cols-4 gap-8 w-full py-8 px-[10px] lg:px-10 xl:px-12 bg-white text-right"
      >
        <div className="lg:col-span-1">
          <Filter formik={formik} />
        </div>
        <div className="lg:col-span-3">
          <Products formik={formik} />
        </div>
      </div>
      <Banners />
      <Footer />
    </div>
  );
};

export default Productspage;
