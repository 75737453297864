import React, { useContext, useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link, useParams } from "react-router-dom";
import { httpReauest } from "../utils/httpRequest";
import Loading from "../components/common/Loading";
import SliderSimilier from "../components/SliderSimilier";
import { CDN_BASE_URL } from "../config";
import { FaBox, FaGift, FaMinus, FaPlus, FaQuora } from "react-icons/fa6";
import CheckoutForm from "../components/CheckoutForm";
import { CartContext } from "../context/CartContext";
import { FaExclamationCircle, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import { priceComma } from "../utils/priceComma";
import { calculateGoldPrice } from "../utils/getPrice";
import { Helmet } from "react-helmet";
import CheckoutPrice from "../components/CheckoutPrice";

const CartPage = () => {
  const [loading, setloading] = useState();
  const [productData, setproductData] = useState();

  const { cart, setCart, goldPrice, allPrice, setallPrice } =
    useContext(CartContext);

  function handleDelete(_id) {
    const newCart = cart.filter((e) => e._id != _id);
    setCart(newCart);
  }

  useEffect(() => {
    let price = 0;

    cart.forEach((item) => {
      price +=
        calculateGoldPrice(goldPrice, item.weight, item.makingFee) * item.count;
    });

    setallPrice(price);
  }, [cart]);

  function handleDecreseCount({
    name,
    category,
    weight,
    special_price,
    img,
    _id,
    count,
  }) {
    setCart((prevCart) => {
      // پیدا کردن ایندکس ایتم مورد نظر در آرایه
      const itemIndex = prevCart.findIndex((item) => item._id === _id);

      // در صورت وجود ایتم، کپی آرایه و تغییر مقدار count
      if (itemIndex !== -1) {
        return [
          ...prevCart.slice(0, itemIndex),
          { name, category, weight, special_price, img, _id, count: count - 1 },
          ...prevCart.slice(itemIndex - 1),
        ];
      }

      // در صورت عدم وجود ایتم، بازگرداندن آرایه قبلی
      return prevCart;
    });
  }

  function handleAddCount({
    name,
    category,
    weight,
    special_price,
    img,
    _id,
    count,
    balance,
  }) {
    if (balance < count + 1) {
      return toast.error("محصول به این تعداد موجود نیست");
    }
    setCart((prevCart) => {
      // پیدا کردن ایندکس ایتم مورد نظر در آرایه
      const itemIndex = prevCart.findIndex((item) => item._id === _id);

      // در صورت وجود ایتم، کپی آرایه و تغییر مقدار count
      if (itemIndex !== -1) {
        return [
          ...prevCart.slice(0, itemIndex),
          { name, category, weight, special_price, img, _id, count: count + 1 },
          ...prevCart.slice(itemIndex + 1),
        ];
      }

      // در صورت عدم وجود ایتم، بازگرداندن آرایه قبلی
      return prevCart;
    });
  }

  console.log(cart);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="hero_area">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"دکتر گلد | سبد خرید"}</title>
        <meta
          name="description"
          content={
            "جستجو و خرید انواع زیورآلات طلا با طراحی‌های خاص و قیمت مناسب در دکتر گلد"
          }
        />
        <meta
          name="og:description"
          content={
            "جستجو و خرید انواع زیورآلات طلا با طراحی‌های خاص و قیمت مناسب در دکتر گلد"
          }
        />
        <meta name="og:title" content={"دکتر گلد | سبد خرید"} />
      </Helmet>
      <Header />
      <div className=" overflow-hidden  py-10  bg-white text-right min-h-[80vh]">
        <div class="grid px-[8px] sm:px-10 lg:grid-cols-3 lg:px-20 xl:px-32 gap-[1rem]">
          <div className="order-2 lg:order-1">
            <CheckoutPrice />
          </div>

          <div class=" pt-8 lg:col-span-2 order-1 lg:order-2">
            <p class="text-xl font-medium">سفارشات</p>
            {cart?.length ? (
              cart.map((item) => (
                <div class="mt-6 space-y-3 rounded-lg border bg-white px-[8px] py-[16px] sm:px-6">
                  <div class="flex flex-col rounded-lg bg-white sm:flex-row">
                    <span className="flex flex-col items-center justify-center">
                      <Link
                        to={
                          item.isSub
                            ? "/products/" + item.isSub
                            : "/products/" + item._id
                        }
                      >
                        <img
                          class="m-2 h-24 w-28 rounded-md border object-cover object-center"
                          src={CDN_BASE_URL + item?.img}
                          alt=""
                        />
                      </Link>
                    </span>

                    <div class="flex w-full flex-col px-4 py-4 items-end">
                      <span className="mb-3">
                        <FaTrash
                          onClick={() => handleDelete(item._id)}
                          color="red"
                          className="cursor-pointer"
                        />
                      </span>
                      <span class="font-semibold">{item?.name}</span>
                      <p class="text-lg font-bold">{item.weight} گرم</p>
                      <p class="text-lg font-bold">
                        {" "}
                        {priceComma(
                          calculateGoldPrice(
                            goldPrice,
                            item.weight,
                            item.makingFee
                          ),
                          2
                        )}{" "}
                        تومان
                      </p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <>
                <div className="w-full h-full text-center  text-[24px] lg:text-[32px] font-bold py-6">
                  سبد خرید شما خالی است
                </div>
              </>
            )}
          </div>
          <p
            dir="rtl"
            className="col-span-full order-last flex items-center gap-2 text-[14px] lg:text-[16px] mt-[1rem]"
          >
            <FaExclamationCircle className="min-w-[20px]" />
            مشتری عزیز موجودی کالا ها محدود می باشد . افزودن محصولات در سبد خرید
            به معنای رزرو برای شما نیست <br />
            برای حذف نشدن محصولات از سبد خرید , خرید خود را کامل کنید
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CartPage;
